import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable, of, switchMap, throwError} from 'rxjs';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {environment} from "../../../environments/environment";

interface OAuthResponseInterface {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  permissions: any;
}

@Injectable()
export class AuthService {
  private authenticated: boolean = false;
  private accessTokenValue: string = ''

  /**
   * Constructor
   */
  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  get accessToken(): string {
    if (!this.accessTokenValue) {
      this.accessTokenValue = localStorage.getItem('accessToken') ?? '';
    }

    return this.accessTokenValue;
  }

  set accessToken(token: string) {
    this.accessTokenValue = token;
    localStorage.setItem('accessToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public forgotPassword(email: string): Observable<any> {
    return this.httpClient.post('api/auth/forgot-password', email);
  }

  public resetPassword(password: string): Observable<any> {
    return this.httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  public signIn(credentials: { email: string; password: string; rememberMe: boolean }): Observable<OAuthResponseInterface> {
    if (this.authenticated) {
      return throwError(() => {
        return 'User is already logged in.'
      });
    }

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    return this.httpClient.post<OAuthResponseInterface>(environment.apiUrl + '/auth/sign-in', credentials).pipe(
      switchMap((response: OAuthResponseInterface) => {
        this.accessToken = response.accessToken;
        this.refreshToken = response.refreshToken;
        setInterval(() => {
          console.log('IntervalExecuted')

          this.signInUsingToken()
        }, (response.expiresIn - 60) * 1000)

        this.authenticated = true;
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  public signInUsingToken(): Observable<boolean> {
    // Renew token
    if (this.refreshToken) {
      return this.httpClient.post<any>(environment.apiUrl + '/auth/refresh', {
        refreshToken: this.refreshToken
      }).pipe(
        catchError(() => of(false)),
        switchMap((response: OAuthResponseInterface) => {
          if (response) {
            this.accessToken = response.accessToken;
            this.refreshToken = response.refreshToken;
            this.authenticated = true;
            setInterval(() => {
              console.log('executed 1')

              this.signInUsingToken()
            }, (response.expiresIn - 60) * 1000)
            // this.userService.user = response.user;
            return of(true);
          }
          this.signOut();
          return of(false);
        })
      );
    } else {
      return of(false);
    }
  }

  /**
   * Sign out
   */
  public signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    // Set the authenticated flag to false
    this.authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this.httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this.authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
