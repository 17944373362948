<!-- Item wrapper -->
<div
  [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
  [ngClass]="item && item.classes && item.classes.wrapper ? item.classes.wrapper : ''"
  class="fuse-vertical-navigation-item-wrapper">

  <!-- Item with an internal link -->
  <ng-container *ngIf="item.link && !item.externalLink && !item.function && !item.disabled">
    <a
      [matTooltip]="item.tooltip || ''"
      [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
      [routerLinkActiveOptions]="isActiveMatchOptions"
      [routerLinkActive]="'fuse-vertical-navigation-item-active'"
      [routerLink]="[item.link]"
      class="fuse-vertical-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <!-- Item with an external link -->
  <ng-container *ngIf="item.link && item.externalLink && !item.function && !item.disabled">
    <a
      [href]="item.link"
      [matTooltip]="item.tooltip || ''"
      [target]="item.target || '_self'"
      class="fuse-vertical-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <!-- Item with a function -->
  <ng-container *ngIf="!item.link && item.function && !item.disabled">
    <div
      (click)="item.function(item)"
      [matTooltip]="item.tooltip || ''"
      [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
      class="fuse-vertical-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>

  <!-- Item with an internal link and function -->
  <ng-container *ngIf="item.link && !item.externalLink && item.function && !item.disabled">
    <a
      (click)="item.function(item)"
      [matTooltip]="item.tooltip || ''"
      [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
      [routerLinkActiveOptions]="isActiveMatchOptions"
      [routerLinkActive]="'fuse-vertical-navigation-item-active'"
      [routerLink]="[item.link]"
      class="fuse-vertical-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <!-- Item with an external link and function -->
  <ng-container *ngIf="item.link && item.externalLink && item.function && !item.disabled">
    <a
      (click)="item.function(item)"
      [href]="item.link"
      [matTooltip]="item.tooltip || ''"
      [target]="item.target || '_self'"
      class="fuse-vertical-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <!-- Item with a no link and no function -->
  <ng-container *ngIf="!item.link && !item.function && !item.disabled">
    <div
      [matTooltip]="item.tooltip || ''"
      [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
      class="fuse-vertical-navigation-item">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>

  <!-- Item is disabled -->
  <ng-container *ngIf="item.disabled">
    <div
      [matTooltip]="item.tooltip || ''"
      class="fuse-vertical-navigation-item fuse-vertical-navigation-item-disabled">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>

</div>

<!-- Item template -->
<ng-template #itemTemplate>

  <!-- Icon -->
  <ng-container *ngIf="item.icon">
    <mat-icon
      [ngClass]="item && item.classes && item.classes.icon ? item.classes.icon : ''"
      [svgIcon]="item.icon"
      class="fuse-vertical-navigation-item-icon"></mat-icon>
  </ng-container>

  <!-- Title & Subtitle -->
  <div class="fuse-vertical-navigation-item-title-wrapper">
    <div class="fuse-vertical-navigation-item-title">
            <span [ngClass]="item && item.classes && item.classes.title ? item.classes.title : ''">
                {{item.title}}
            </span>
    </div>
    <ng-container *ngIf="item.subtitle">
      <div class="fuse-vertical-navigation-item-subtitle">
                <span [ngClass]="item && item.classes && item.classes.subtitle ? item.classes.subtitle : ''">
                    {{item.subtitle}}
                </span>
      </div>
    </ng-container>
  </div>

  <!-- Badge -->
  <ng-container *ngIf="item.badge">
    <div class="fuse-vertical-navigation-item-badge">
      <div
        [ngClass]="item && item.badge && item.badge.classes ? item.badge.classes : ''"
        class="fuse-vertical-navigation-item-badge-content">
        {{item.badge.title}}
      </div>
    </div>
  </ng-container>

</ng-template>
