<!-- Shortcuts toggle -->
<button
  #shortcutsOrigin
  (click)="openPanel()"
  mat-icon-button>
  <mat-icon [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>
</button>

<!-- Shortcuts panel -->
<ng-template #shortcutsPanel>
  <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

    <!-- Header -->
    <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
      <div class="sm:hidden -ml-1 mr-3">
        <button
          (click)="closePanel()"
          mat-icon-button>
          <mat-icon
            [svgIcon]="'heroicons_solid:x'"
            class="icon-size-5 text-current"></mat-icon>
        </button>
      </div>
      <div class="flex items-center text-lg font-medium leading-10">
        <span class="">Shortcuts</span>
        <ng-container *ngIf="mode !== 'view'">
                    <span class="ml-1">
                        <ng-container *ngIf="mode === 'add'">- Add new</ng-container>
                        <ng-container *ngIf="mode === 'modify' || mode === 'edit'">- Editing</ng-container>
                    </span>
        </ng-container>
      </div>
      <div class="ml-auto">

        <!-- View mode -->
        <ng-container *ngIf="mode === 'view'">
          <!-- Enter 'modify' mode -->
          <button
            (click)="changeMode('modify')"
            [matTooltip]="'Enter edit mode'"
            mat-icon-button>
            <mat-icon
              [svgIcon]="'heroicons_solid:pencil-alt'"
              class="icon-size-5 text-current"></mat-icon>
          </button>
          <!-- Enter 'add' mode -->
          <button
            (click)="newShortcut()"
            [matTooltip]="'Add shortcut'"
            mat-icon-button>
            <mat-icon
              [svgIcon]="'heroicons_solid:plus-circle'"
              class="icon-size-5 text-current"></mat-icon>
          </button>
        </ng-container>

        <!-- Modify mode -->
        <ng-container *ngIf="mode === 'modify'">
          <!-- Enter 'view' mode -->
          <button
            (click)="changeMode('view')"
            [matTooltip]="'Exit edit mode'"
            mat-icon-button>
            <mat-icon
              [svgIcon]="'heroicons_solid:check-circle'"
              class="icon-size-5 text-current"></mat-icon>
          </button>
        </ng-container>

        <!-- Add mode -->
        <ng-container *ngIf="mode === 'add'">
          <!-- Enter 'view' mode -->
          <button
            (click)="changeMode('view')"
            [matTooltip]="'Cancel'"
            mat-icon-button>
            <mat-icon
              [svgIcon]="'heroicons_solid:x-circle'"
              class="icon-size-5 text-current"></mat-icon>
          </button>
        </ng-container>

        <!-- Edit mode -->
        <ng-container *ngIf="mode === 'edit'">
          <!-- Enter 'modify' mode -->
          <button
            (click)="changeMode('modify')"
            [matTooltip]="'Cancel'"
            mat-icon-button>
            <mat-icon
              [svgIcon]="'heroicons_solid:x-circle'"
              class="icon-size-5 text-current"></mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="relative flex flex-col flex-auto sm:max-h-120 -mb-px overflow-y-auto bg-card">

      <!-- View mode -->
      <ng-container *ngIf="mode === 'view' || mode === 'modify'">

        <!-- Shortcuts -->
        <div class="grid grid-cols-2 grid-flow-row">
          <!-- Shortcut -->
          <ng-container *ngFor="let shortcut of shortcuts; trackBy: trackByFn">
            <div
              class="relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">
              <ng-container *ngIf="mode === 'modify'">
                <div
                  (click)="editShortcut(shortcut)"
                  class="absolute inset-0 z-99 cursor-pointer">
                </div>
              </ng-container>
              <!-- Normal links -->
              <a
                *ngIf="!shortcut.useRouter"
                [href]="shortcut.link"
                [ngClass]="{'pointer-events-none': mode === 'modify'}"
                class="flex flex-col items-center justify-center w-full h-full py-6 no-underline">
                <ng-container *ngTemplateOutlet="linkContent"></ng-container>
              </a>
              <!-- Router links -->
              <a
                *ngIf="shortcut.useRouter"
                [ngClass]="{'pointer-events-none': mode === 'modify'}"
                [routerLink]="shortcut.link"
                class="flex flex-col items-center justify-center w-full h-full py-6 no-underline">
                <ng-container *ngTemplateOutlet="linkContent"></ng-container>
              </a>
              <!-- Link content template -->
              <ng-template #linkContent>
                <div class="relative flex shrink-0 items-center justify-center w-12 h-12 mb-3 rounded-full bg-gray-100 dark:bg-gray-700">
                  <mat-icon
                    *ngIf="mode === 'modify'"
                    [svgIcon]="'heroicons_solid:pencil'"
                    class="absolute opacity-0 group-hover:opacity-100 z-20 icon-size-5"></mat-icon>
                  <mat-icon
                    [ngClass]="{'group-hover:opacity-0': mode === 'modify'}"
                    [svgIcon]="shortcut.icon"
                    class="z-10"></mat-icon>
                </div>
                <div class="font-medium text-center">{{shortcut.label}}</div>
                <div class="text-md text-center text-secondary">{{shortcut.description}}</div>
              </ng-template>
            </div>
          </ng-container>
        </div>

        <!-- No shortcuts -->
        <ng-container *ngIf="!shortcuts || !shortcuts.length">
          <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
            <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
              <mat-icon
                [svgIcon]="'heroicons_outline:bookmark'"
                class="text-primary-500-700"></mat-icon>
            </div>
            <div class="mt-5 text-2xl font-semibold tracking-tight">No shortcuts</div>
            <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have shortcuts, they will appear here.</div>
          </div>
        </ng-container>
      </ng-container>

      <!-- Add/Edit mode -->
      <ng-container *ngIf="mode === 'add' || mode === 'edit'">
        <form
          [formGroup]="shortcutForm"
          class="p-6">
          <mat-form-field class="w-full">
            <mat-label>Label</mat-label>
            <input
              [formControlName]="'label'"
              matInput
              required>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Description</mat-label>
            <input
              [formControlName]="'description'"
              matInput>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Icon</mat-label>
            <input
              [formControlName]="'icon'"
              matInput
              required>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Link</mat-label>
            <input
              [formControlName]="'link'"
              matInput
              required>
          </mat-form-field>
          <mat-slide-toggle
            [color]="'primary'"
            [formControlName]="'useRouter'">
            Use router for the link
          </mat-slide-toggle>
          <!-- Actions -->
          <div class="flex items-center justify-end mt-4">
            <button
              (click)="delete()"
              *ngIf="mode === 'edit'"
              class="mr-2"
              mat-flat-button
              type="button">
              Delete
            </button>
            <button
              (click)="save()"
              [color]="'primary'"
              [disabled]="!shortcutForm.valid"
              mat-flat-button
              type="button">
              <ng-container *ngIf="mode === 'add'">Add</ng-container>
              <ng-container *ngIf="mode === 'edit'">Update</ng-container>
            </button>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</ng-template>
