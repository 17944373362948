import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, ReplaySubject, tap} from 'rxjs';
import {User} from 'app/core/user/user.types';
import {environment} from "../../../environments/environment";
import {PermissionsService} from "../../utils/helpers/permissions.service";
import qs from "qs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private statusSource = new BehaviorSubject('OFF');
  currentStatus = this.statusSource.asObservable();
  constructor(
    private _httpClient: HttpClient,
    private _permissionsService: PermissionsService
  ) {
  }

  role = 'user'

  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  set user(value: User) {
    // Store the value
    this._user.next(value);
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  private _userList: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);

  set userList(value: User[]) {
    // Store the value
    this._userList.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  get userList$(): Observable<User[]> {
    return this._userList.asObservable();
  }

  public get(): Observable<User> {
    return this._httpClient.get<User>(environment.apiUrl + '/user/me').pipe(
      tap((user) => {
        this.role = user.role
        this._permissionsService.permissions = user.permissions
        this._user.next(user);
      })
    );
  }

  public post(form: FormData) {
    return this._httpClient.post<User>(`${environment.apiUrl}/user`, form)
  }

  public update(form: FormData, id: string) {
    return this._httpClient.patch<User>(`${environment.apiUrl}/user/${id}`, form)
  }


  public delete(id: string): Observable<any> {
    return this._httpClient.delete<User>(`${environment.apiUrl}/user/${id}`);
  }

  public list(body: any) {
    return this._httpClient.get<User[]>(`${environment.apiUrl}/user?${qs.stringify(body)}`).pipe(
      tap((response: any) => {
        this._userList.next(response);
      })
    )
  }

  changeStatus(status: string) {
    this.statusSource.next(status)
  }
}
