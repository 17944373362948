<div
  [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
  [ngClass]="item && item.classes && item.classes.wrapper ? item.classes.wrapper : ''"
  class="fuse-vertical-navigation-item-wrapper">

  <div
    [matTooltip]="item.tooltip || ''"
    [ngClass]="{'fuse-vertical-navigation-item-active': active,
                    'fuse-vertical-navigation-item-disabled': item.disabled,
                    'fuse-vertical-navigation-item-active-forced': item.active}"
    class="fuse-vertical-navigation-item">

    <!-- Icon -->
    <ng-container *ngIf="item.icon">
      <mat-icon
        [ngClass]="item && item.classes && item.classes.icon ? item.classes.icon : ''"
        [svgIcon]="item.icon"
        class="fuse-vertical-navigation-item-icon"></mat-icon>
    </ng-container>

    <!-- Title & Subtitle -->
    <div class="fuse-vertical-navigation-item-title-wrapper">
      <div class="fuse-vertical-navigation-item-title">
                <span [ngClass]="item && item.classes && item.classes.title ? item.classes.title : ''">
                    {{item.title}}
                </span>
      </div>
      <ng-container *ngIf="item.subtitle">
        <div class="fuse-vertical-navigation-item-subtitle">
                    <span [ngClass]="item && item.classes && item.classes.subtitle ? item.classes.subtitle : ''">
                        {{item.subtitle}}
                    </span>
        </div>
      </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
      <div class="fuse-vertical-navigation-item-badge">
        <div
          [ngClass]="item && item.badge && item.badge.classes ? item.badge.classes : ''"
          class="fuse-vertical-navigation-item-badge-content">
          {{item.badge.title}}
        </div>
      </div>
    </ng-container>

  </div>

</div>

<ng-container *ngIf="!skipChildren">

  <div class="fuse-vertical-navigation-item-children">

    <ng-container *ngFor="let item of item.children; trackBy: trackByFn">

      <!-- Skip the hidden items -->
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

        <!-- Basic -->
        <ng-container *ngIf="item.type === 'basic'">
          <fuse-vertical-navigation-basic-item
            [item]="item"
            [name]="name"></fuse-vertical-navigation-basic-item>
        </ng-container>

        <!-- Collapsable -->
        <ng-container *ngIf="item.type === 'collapsable'">
          <fuse-vertical-navigation-collapsable-item
            [autoCollapse]="autoCollapse"
            [item]="item"
            [name]="name"></fuse-vertical-navigation-collapsable-item>
        </ng-container>

        <!-- Divider -->
        <ng-container *ngIf="item.type === 'divider'">
          <fuse-vertical-navigation-divider-item
            [item]="item"
            [name]="name"></fuse-vertical-navigation-divider-item>
        </ng-container>

        <!-- Group -->
        <ng-container *ngIf="item.type === 'group'">
          <fuse-vertical-navigation-group-item
            [item]="item"
            [name]="name"></fuse-vertical-navigation-group-item>
        </ng-container>

        <!-- Spacer -->
        <ng-container *ngIf="item.type === 'spacer'">
          <fuse-vertical-navigation-spacer-item
            [item]="item"
            [name]="name"></fuse-vertical-navigation-spacer-item>
        </ng-container>

      </ng-container>

    </ng-container>

  </div>

</ng-container>
