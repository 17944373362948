import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  permissions!: [{ name: string; accesses: string[] }];
  userPermissions!: { name: string; accesses: string[] }

  constructor() {
  }

  public checkPermissions(moduleName: string) {
    this.permissions.findIndex((object: { name: string; accesses: string[] }) => {
      if (object.name === moduleName) {
        this.userPermissions = object
      }
    });
  }

  public haveAccess(permission: string) {
    return this.userPermissions.accesses.includes(permission)
  }

}
