import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from 'environments/environment';
import {AppModule} from 'app/app.module';
import packageJson from '../package.json';

if (environment.production) {
  enableProdMode();
}

console.log(`App version ${packageJson.version}`);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
